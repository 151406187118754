import axios from "axios";

const clientHttp = axios.create({
    baseURL: 'https://apinnovare.techvision.dev/api/',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
})


export default clientHttp;
