// Actions Usuários
export const GET_USERS = 'GET_USERS';
export const OPTIONS_USERS = 'OPTIONS_USERS';
export const REGISTER_USERS = 'REGISTER_USERS';
export const DELETE_USERS = 'DELETE_USERS';

// Actions Categorias
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const REGISTER_CATEGORIES = 'REGISTER_CATEGORIES';
export const DELETE_CATEGORIES = 'DELETE_CATEGORIES';
export const GET_OPTIONS = 'GET_OPTIONS';

// Actions Solicitações
export const REGISTER_PENDING = 'REGISTER_PENDING';
export const DELETE_SOLICITATIONS = 'DELETE_SOLICITATIONS';
export const DELETE_DETAIL = 'DELETE_DETAIL';
export const GET_PENDING = 'GET_PENDING';
export const NEW_STATUS_SOLICITATIONS = 'NEW_STATUS_SOLICITATIONS';
export const GET_RESOLVED = 'GET_RESOLVED';
